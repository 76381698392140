.App {
	text-align: center;
}

.App-logo {
	height: 50vmin;
	pointer-events: none;
}
.scrollDown {
	height: 10vmin;
	pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 25s linear;
	}
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.App-logo {
	/*background-color: red;*/
}
.App-logo-container {
	display: block;
	position: relative;
	margin-top: -50px;
}
.App-logo-overlay {
	position: absolute;
	vertical-align: middle;
	text-align: center;
	display: inline;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	z-index: 1;
}


.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.devCycleText {
	font-variant:petite-caps;
	font-size: smaller;
}

.is-current {
	font-weight: bold;
}

@media screen and (min-width: 768px) {
	.scrollspynav {
		display: flex;
		/*background-color: red;*/
	}
}
.scrollspynav {
	margin: 12px;
	padding: 0;
	list-style-type: none;
}

.NavTargetBlock {
	margin-top: 10px;
}
.anchorPadTop {
	margin-top: 100px;
}

.imgMilkshakeBox {
	width: 500px;
	height: 202px;
	background: url(../public/images/i-drink-your-milkshake-gif-3.png) no-repeat;
}
.imgMilkshakeBox:hover {
	width: 500px;
	height: 202px;
	background: url(../public/images/i-drink-your-milkshake-gif-3.gif) no-repeat;
}

.contAlt {
	border: solid 1px;
	border-color: lightgray;
	background-color: whitesmoke;
	padding:10px;
	margin-top: 20px;
}
.contNormal {
	border: solid 1px;
	border-color: whitesmoke;
	padding:10px;
	margin-top: 20px;
}
.smallText {
	font-size: small;
}
.copyrightBlock {
	color: darkgray;
	font-size: small;
}

.wellboreOverlay {
	background-color:red;
	/*display: none;*/
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}


